/** @jsxImportSource @emotion/react */
import { useTranslation } from 'react-i18next'
import { I18N_REPORT_NS } from '../../../i18n'
import { Space, Typography } from 'antd'
import { css } from '@emotion/css'
import { renderQuarter } from '..'

const vertSpaceProps = { direction: 'vertical', style: { width: '100%' } }

export const Runway = ({ reports }) => {
	const { t } = useTranslation([I18N_REPORT_NS])
	return (
		<Space {...vertSpaceProps} size={0}>
			<Typography.Text strong className={css({ fontSize: 14, lineHeight: 1 })}>
				{t('runway_title')}
			</Typography.Text>
			<Space direction='vertical'>
				{reports?.map(({ run_way, quarter }) => (
					<Space key={quarter} size={8}>
						<Typography.Text strong className={css({ fontSize: 12 })}>
							{quarter && `${renderQuarter(quarter)}:`}
						</Typography.Text>
						<Typography.Text className={css({ fontSize: 12 })}>{run_way}</Typography.Text>
					</Space>
				))}
			</Space>
		</Space>
	)
}
