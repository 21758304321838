import { useModalForm } from '@refinedev/antd'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from '@tanstack/react-query'
import { useState, createContext, useContext } from 'react'
import { Form, InputNumber, Modal, Row, Col, Select, Switch } from 'antd'

import { LegalEntitySelector } from '../selects/entitySelectors'

import { I18N_DEAL_NS } from '../../i18n'
import { getStartCase } from '../../utils/formatting'

const { Item } = Form

const CommitContext = createContext()

const CommitModal = ({ modalProps, formProps }) => {
	const { t } = useTranslation([I18N_DEAL_NS])

	// todo: defaults by deal

	return (
		<Modal {...modalProps} destroyOnClose={true} forceRender={true} width={720}>
			<Form {...formProps}>
				<Item hidden name={['deal_id']} />
				<Row gutter={[16, 16]} justify='space-between'>
					<Col span={18}>
						<Item
							name={['type']}
							labelAlign='left'
							labelCol={{ span: 8 }}
							label={t('commit_type')}
							rules={[{ required: 'true', message: '' }]}
						>
							<Select options={['soft', 'hard'].map(v => ({ label: getStartCase(v), value: v }))} />
						</Item>
					</Col>
					<Col>
						<Item
							name={['anonymous']}
							labelCol={{ span: 0 }}
							valuePropName='checked'
							label={t('commit_anonymous')}
						>
							<Switch />
						</Item>
					</Col>
				</Row>
				<Item
					name={['amount']}
					labelAlign='left'
					labelCol={{ span: 6 }}
					label={t('commit_amount')}
					rules={[{ required: 'true', message: '' }]}
				>
					<InputNumber style={{ width: '100%' }} />
				</Item>
				<Item
					name={['vehicle_id']}
					labelAlign='left'
					labelCol={{ span: 6 }}
					label={t('commit_vehicle_id')}
					rules={[{ required: 'true', message: '' }]}
				>
					<LegalEntitySelector />
				</Item>
			</Form>
		</Modal>
	)
}

const getCommonUseModalFormProps = (t, action) => ({
	redirect: false,
	resource: 'investments',
	successNotification: {
		message: `Commit has been ${action === 'create' ? 'created' : 'edited'} successfully`,
		type: 'success'
	},
	errorNotification: error => {
		const errDetail = error.response?.data?.detail
		console.error('errDetail', errDetail)

		return {
			message: errDetail ? t(`commit_errors.${errDetail}`) : t('commit_error'),
			type: 'error'
		}
	}
})

const CommitWrapper = ({ children }) => {
	const [deal, setDeal] = useState()
	const investmentId = deal?.investment_id

	const queryClient = useQueryClient()
	const [queryKeyToInvalidate, setQueryKeyToInvalidate] = useState()

	const { t } = useTranslation([I18N_DEAL_NS])

	const onMutationSuccess = () => {
		if (queryKeyToInvalidate) {
			queryClient.invalidateQueries(queryKeyToInvalidate)
		}
	}

	const {
		modalProps: editModalProps,
		formProps: editFormProps,
		show: editShow
	} = useModalForm({
		action: 'edit',
		id: investmentId,
		onMutationSuccess,
		...getCommonUseModalFormProps(t, 'edit')
	})

	const {
		modalProps: createModalProps,
		formProps: createFormProps,
		show: createShow,
		form: createForm
	} = useModalForm({
		action: 'create',
		onMutationSuccess,
		...getCommonUseModalFormProps(t, 'create')
	})

	const openCommitModal = (deal, queryKeyToInvalidateOnSuccess) => {
		setDeal(deal)
		setQueryKeyToInvalidate(queryKeyToInvalidateOnSuccess)

		if (deal.investment_id) {
			editShow(deal.investment_id)
		} else {
			createForm.setFieldsValue({ deal_id: deal.id, amount: deal.min_commit_amount, type: 'soft' })
			createShow()
		}
	}

	return (
		<CommitContext.Provider value={openCommitModal}>
			<CommitModal modalProps={editModalProps} formProps={editFormProps} />
			<CommitModal modalProps={createModalProps} formProps={createFormProps} />
			{children}
		</CommitContext.Provider>
	)
}

CommitWrapper.useCommitModal = () => useContext(CommitContext)

export default CommitWrapper
