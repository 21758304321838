import { useEffect, useState } from 'react'
import { Space, Pagination, Card, Tabs } from 'antd'
import { useApiUrl, useCustom, useNavigation } from '@refinedev/core'

import Warning from '../components/warning'
import ItemsGrid from '../containers/itemsGrid'
import CommitWrapper from '../components/commits/commitModal'
import ControlPanelHeader from '../containers/controlPanelHeader'

import { getFirstValue } from '../utils/helpers'
import { formatCurrency } from '../utils/formatting'

import { redLight, greenLight, yellowLight, violetLight, tagsTextColor } from '../theme'

const DEALS_PER_PAGE = 10

export const stages = {
	fff: { title: 'FFF' },
	pre_seed: { title: 'Pre-seed' },
	seed: { title: 'Seed' },
	pre_series_a: { title: 'Pre-series A' },
	series_a: { title: 'Series A' },
	pre_series_b: { title: 'Pre-series B' },
	series_b: { title: 'Series B' },
	pre_series_c: { title: 'Pre-series C' },
	series_c: { title: 'Series C' },
	pre_series_d: { title: 'Pre-series D' },
	series_d: { title: 'Series D' },
	pre_ipo: { title: 'Pre-IPO' },
	ipo: { title: 'IPO' }
}

export const states = {
	collect_commits: { title: 'Collect commits' },
	alloc_confirmation: { title: 'Alloc confirmation' },
	completed: { title: 'Completed' },
	rejected: { title: 'Rejected' },
	failed: { title: 'Failed' },
	ic_approval: { title: 'IC Approval' }
}

export const getDealTitle = ({ name, number }) => `${name} ${number}`

export const getDealProgress = ({ soft_commitment = 0, hard_commitment = 0, allocation = 0 }) => {
	const total_commitment = soft_commitment + hard_commitment

	return {
		title: `${formatCurrency(total_commitment)} / ${formatCurrency(allocation)}`,
		percent: (total_commitment / allocation) * 100,
		firstValue: formatCurrency(total_commitment),
		secondValue: formatCurrency(allocation)
	}
}

const getDealIndustriesTags = ({ industry_names }) => [
	...(industry_names?.map(ind => ({ title: ind, color: violetLight })) || [])
]

export const getDealHeaderTags = ({ hot: hotDeal, new: newDeal, fund_eligible, industry_names, stage }) => [
	...(hotDeal ? [{ title: 'Hot', color: redLight }] : []),
	...(newDeal ? [{ title: 'New', color: greenLight }] : []),
	...(fund_eligible ? [{ title: 'Fund Eligible', color: 'yellow', textColor: tagsTextColor }] : []),
	...getDealIndustriesTags({ industry_names }),
	{ color: yellowLight, ...stages[stage] }
]

export const getDealDescriptors = ({ stage, syndicators }) => [
	...(stage && stages[stage] ? [{ title: 'Stage', value: stages[stage]?.title }] : []),
	...(syndicators?.length ? [{ title: 'Syndicator', value: syndicators.join(', ') }] : [])
]

const mapRowsToItems = (rows, onButtonClick) =>
	rows?.map(item => {
		const {
			id,
			name,
			stage,
			number,
			oneliner,
			allocation,
			syndicators,
			hot: hotDeal,
			new: newDeal,
			fund_eligible,
			investment_id,
			industry_names,
			soft_commitment,
			hard_commitment
		} = item

		return {
			id,
			key: id,
			description: getFirstValue(oneliner),
			title: getDealTitle({ name, number }),
			bottomItemImagePath: `deals/${id}/logo`,
			onButtonClick: event => onButtonClick(event, item),
			descriptors: getDealDescriptors({ stage, syndicators }),
			buttonTextKey: investment_id ? 'commit_change' : 'commit_make',
			progress: getDealProgress({ soft_commitment, hard_commitment, allocation }),
			headerTags: getDealHeaderTags({ hot: hotDeal, new: newDeal, fund_eligible, industry_names, stage })
		}
	})

const showcaseTabs = [
	{ key: 'active', label: 'Active deals' }
	// { key: 'archive', label: 'Archive deals' }
]

const ShowcasePage = () => {
	const [dealSearchString, setDealSearchString] = useState()
	const [page, setPage] = useState(1)

	const filters = [{ field: 'search', operator: 'eq', value: dealSearchString }]
	const pagination = { current: page, pageSize: DEALS_PER_PAGE }

	// const { data: { data: deals, total } = {}, ...rest } = useList({
	// 	resource: 'deals',
	// 	filters,
	// 	pagination,
	// 	queryOptions: {
	// 		queryKey: ['showcase']
	// 	}
	// })

	const apiUrl = useApiUrl()

	// usage useCustom instead of useList due to not working queryKey there
	// todo: return useList when queryKey will be fixed
	const { data: { data: { rows: deals, count: total } = {} } = {}, refetch } = useCustom({
		url: `${apiUrl}/deals`,
		config: {
			filters,
			pagination
		},
		queryOptions: {
			queryKey: ['showcase']
		}
	})

	useEffect(() => {
		refetch()
	}, [refetch, dealSearchString, page])

	const { push } = useNavigation()

	const itemClickHandler = item => push(`deals/${item.id}`)

	const openCommitModal = CommitWrapper.useCommitModal()

	const onButtonClick = (event, deal) => {
		event.stopPropagation()
		openCommitModal(deal, ['showcase'])
	}

	return (
		<Space direction='vertical' size='large' style={{ width: '100%' }}>
			<ControlPanelHeader onSearch={setDealSearchString} />
			<Card>
				<Space direction='vertical' size='large' style={{ width: '100%' }}>
					<Space direction='vertical' size='small' style={{ width: '100%' }}>
						<Tabs items={showcaseTabs} />
						{total ? (
							<ItemsGrid items={mapRowsToItems(deals, onButtonClick)} onItemClick={itemClickHandler} />
						) : null}
					</Space>
					{total ? (
						<Pagination
							total={total}
							onChange={setPage}
							pageSize={DEALS_PER_PAGE}
							defaultCurrent={1 || page}
						/>
					) : null}
					<Warning />
				</Space>
			</Card>
		</Space>
	)
}

export default ShowcasePage
