import { useRef } from 'react'
import { Edit } from '@refinedev/antd'
import { useTranslation } from 'react-i18next'
import { Col, Divider, Space, Typography } from 'antd'

import Avatar from './avatar'
import Profile from './profile'
import Languages from './languages'

import { I18N_SETTINGS_NS } from '../../../i18n'

const { Text } = Typography

const SETTINGS_COL_SPANS = { xxl: 8, xl: 8, lg: 12, md: 12, sm: 24, xs: 24 }

export const SettingsFieldColWrapper = ({ name, children }) => {
	const { t } = useTranslation([I18N_SETTINGS_NS])

	return (
		<Col {...SETTINGS_COL_SPANS}>
			<Space direction='vertical' style={{ width: '100%' }}>
				<Text>{t(`field_title.${name}`)}</Text>
				{children}
			</Space>
		</Col>
	)
}

const ProfilePage = () => {
	const { t } = useTranslation([I18N_SETTINGS_NS])

	const saveHandlerToEnrich = useRef({})

	const enrichSaveHandler = (saveHandler, key) => {
		saveHandlerToEnrich.current[key] = saveHandler

		return () => {
			delete saveHandlerToEnrich.current[key]
		}
	}

	const saveHandler = () => Object.values(saveHandlerToEnrich.current).forEach(f => f())

	return (
		<Edit title={t('my_profile')} headerButtons={[]} saveButtonProps={{ onClick: saveHandler }} breadcrumb={false}>
			<Avatar enrichSaveHandler={enrichSaveHandler} />
			<Profile enrichSaveHandler={enrichSaveHandler} />
			<Divider />
			<Languages enrichSaveHandler={enrichSaveHandler} />
		</Edit>
	)
}

export default ProfilePage
