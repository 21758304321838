import { Select } from 'antd'
import { memo } from 'react'
import { useTranslation } from 'react-i18next'
import { langs } from '../utils/constants'
import { css } from '@emotion/css'

import { layoutBackgroundStyle } from '../theme'

export const LangSwitcher = memo(() => {
	const { i18n } = useTranslation()

	const options = langs.reduce(
		(acc, { key, label, text }) => [
			...acc,
			{
				value: key,
				label: `${label} ${text}`
			}
		],
		[]
	)

	const changeLanguage = lang => {
		i18n.changeLanguage(lang)
	}
	return (
		<Select
			defaultValue={i18n.language}
			style={{ width: 120 }}
			onChange={changeLanguage}
			options={options}
			className={css({
				'.ant-select-selector': { ...layoutBackgroundStyle, color: 'white' },
				'.ant-select-arrow': { color: 'white' }
			})}
		/>
	)
})

export default LangSwitcher
