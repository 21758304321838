import { Tag, Row, Col } from 'antd'

import { primaryColor, tagsTextColor } from '../theme'

const RowTag = ({ title, color, textColor, Icon }) => (
	<Tag color={color || primaryColor} style={{ margin: 0, color: textColor || tagsTextColor }}>
		{Icon && <Icon />}
		{title}
	</Tag>
)

const TagsRow = ({ tags, justify = 'end' }) => (
	<Row gutter={[8, 8]} justify={justify}>
		{tags
			?.filter(({ title }) => !!title)
			.map(({ title, ...tag }) => (
				<Col key={title} style={{ maxWidth: 'none' }}>
					<RowTag {...{ title, ...tag }} />
				</Col>
			))}
	</Row>
)

export default TagsRow
