import { useState } from 'react'
import { Col, Form, Input, Row, Switch } from 'antd'

import { EntitySelector } from './entitySelectors'

const { Item } = Form

const RecommenderSelect = ({ ...props }) => (
	<EntitySelector
		resource='investors'
		nameFactory={r => `${r.first_name || r.alt_first_name1} ${r.last_name || r.alt_last_name1}`}
		{...props}
	/>
)

const formItemNamesByInternal = {
	true: ['recommender_id'],
	false: ['recommender_other']
}

export const RecommenderInputItemWrapper = ({ name, isEditForbidden, ...props }) => {
	const form = Form.useFormInstance()

	const getDefaultRecommenderInternalState = () => {
		const isRecId = !!form.getFieldValue(['recommender_id'])
		const isRecOther = !!form.getFieldValue(['recommender_other'])

		if (isRecId) return true
		if (!isRecId && !isRecOther) return true
		return false
	}
	const [isRecommenderInternal, setIsRecommenderInternal] = useState(getDefaultRecommenderInternalState())

	return (
		<Row gutter={[16, 16]} align='middle'>
			<Col flex='auto'>
				<Item {...props} name={formItemNamesByInternal[isRecommenderInternal]} preserve={false}>
					{isRecommenderInternal ? (
						<RecommenderSelect disabled={isEditForbidden} />
					) : (
						<Input disabled={isEditForbidden} />
					)}
				</Item>
			</Col>
			<Col span={6}>
				<Switch
					disabled={isEditForbidden}
					checkedChildren='Internal'
					unCheckedChildren='External'
					checked={isRecommenderInternal}
					onChange={setIsRecommenderInternal}
				/>
			</Col>
		</Row>
	)
}
