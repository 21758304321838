/** @jsxImportSource @emotion/react */
import { useTranslation } from 'react-i18next'
import { I18N_REPORT_NS } from '../../../i18n'
import { Space, Grid, Typography, Card } from 'antd'
import { css } from '@emotion/css'
import { paragraphStyle } from '../../../theme'
import { classes } from '../../style'
import { renderQuarter } from '../index'
import dayjs from 'dayjs'
const { useBreakpoint } = Grid

const vertSpaceProps = { direction: 'vertical', style: { width: '100%' } }

const QuarterSyndicatorSummaryCard = ({ quarter, text }) => {
	const { xs } = useBreakpoint()

	if (!text) return null

	return (
		<Card
			className={css({
				backgroundColor: xs ? 'white' : 'var(--violet-bg)',
				marginTop: 14,
				'.ant-card-body': {
					flexDirection: 'column',
					display: 'flex'
				}
			})}
		>
			<Typography.Text
				strong
				className={css({ fontSize: 14, lineHeight: 1, paddingBottom: 8, color: 'var(--violet-dark)' })}
			>
				{quarter && `${renderQuarter(quarter)}:`}
			</Typography.Text>
			<Typography.Text className={css({ fontSize: 12, whiteSpace: 'pre-line' })}>{text}</Typography.Text>
		</Card>
	)
}

export const SyndicatorSummary = ({ reports }) => {
	const { t } = useTranslation([I18N_REPORT_NS])
	return (
		<Space {...vertSpaceProps} size={0}>
			<Space {...vertSpaceProps} size={0}>
				<Typography.Text strong className={css({ fontSize: 14, lineHeight: 1 })}>
					{t('syndicator_summary_title')}
				</Typography.Text>
				<Typography.Text className={css({ ...paragraphStyle, fontSize: 10, lineHeight: 1 })}>
					{t('syndicator_summary_notes')}
				</Typography.Text>
			</Space>

			<Space css={classes.columns}>
				{reports
					?.sort((a, b) => (dayjs(a.quarter).diff(dayjs(b.quarter)) > 0 ? -1 : 1))
					?.map(({ syndicator_summary, quarter }) => (
						<QuarterSyndicatorSummaryCard quarter={quarter} key={quarter} text={syndicator_summary} />
					))}
			</Space>
		</Space>
	)
}
