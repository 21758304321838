import { AutoComplete } from 'antd'

const CustomAutoComplete = ({ options, ...props }) => (
	<AutoComplete
		filterOption={(inputValue, option) => option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1}
		options={options.map(({ label }) => ({ value: label, label }))}
		{...props}
	/>
)

export default CustomAutoComplete
