import i18n from 'i18next'
import HttpApi from 'i18next-http-backend'
import { initReactI18next } from 'react-i18next'

export const DEFAULT_FALLBACK_LANGUAGE = 'en'

export const I18N_DEAL_NS = 'deal'
export const I18N_SIDER_NS = 'sider'
export const I18N_WALLET_NS = 'wallet'
export const I18N_GENERIC_NS = 'generic'
export const I18N_SETTINGS_NS = 'settings'
export const I18N_PORTFOLIO_NS = 'portfolio'
export const I18N_FORM_NS = 'form'
export const I18N_REPORT_NS = 'report'

export const manualHref = 'https://angelsdeck.notion.site/Angelsdeck-8558c8dcdaaa40e9a928ab53c0f12dad?pvs=4'

const backendOptions = {
	loadPath: '/locales/{{lng}}/{{ns}}.json'
}

i18n.use(HttpApi)
	.use(initReactI18next)
	.init({ backend: backendOptions, fallbackLng: DEFAULT_FALLBACK_LANGUAGE, react: { useSuspense: false } })

export const updateLang = async lang => {
	if (lang && lang !== i18n.language) {
		console.log(`Update lang to ${lang}, previous was ${i18n.language}`)

		localStorage.setItem(
			'adUserData',
			JSON.stringify({ ...JSON.parse(localStorage.getItem('adUserData')), interface_lang: lang })
		)
		await i18n.changeLanguage(lang)
	}
}

export default i18n
