/** @jsxImportSource @emotion/react */
import { classes } from './style'

const InfoButton = ({ info }) => {
	return (
		<div css={classes.tooltip} data-title={info}>
			<div css={classes.btn}>
				<div css={classes.icon}>i</div>
			</div>
		</div>
	)
}

export default InfoButton
