/** @jsxImportSource @emotion/react */
import { useTranslation } from 'react-i18next'
import { I18N_REPORT_NS } from '../../../i18n'
import { Space, Typography } from 'antd'
import { css } from '@emotion/css'

const vertSpaceProps = { direction: 'vertical', style: { width: '100%' } }

export const InvestmentEvents = ({ reports }) => {
	const { t } = useTranslation([I18N_REPORT_NS])
	return (
		<Space {...vertSpaceProps} size={0}>
			<Typography.Text strong className={css({ fontSize: 14, lineHeight: 1 })}>
				{t('investment_events_title')}
			</Typography.Text>
			<Space direction='vertical'>
				{reports?.map(({ investment_events }) =>
					investment_events ? (
						<Typography.Text className={css({ fontSize: 12, whiteSpace: 'pre-line' })}>
							{investment_events}
						</Typography.Text>
					) : null
				)}
			</Space>
		</Space>
	)
}
