import { Input, Space } from 'antd'
import { langsByKey } from '../../../../utils/constants'
import { css } from '@emotion/css'

export const UrlInput = ({ onChange, value, ...restProps }) => {
	return (
		<Space direction='vertical' className={css({ width: '100%' })}>
			<Input
				{...restProps}
				addonBefore={langsByKey.en.label}
				value={value?.en}
				onChange={e => onChange({ ...value, en: e.target.value })}
				allowClear
				key='en'
			/>
			<Input
				{...restProps}
				addonBefore={langsByKey.ru.label}
				value={value?.ru}
				onChange={e => onChange({ ...value, ru: e.target.value })}
				allowClear
				key='ru'
			/>
		</Space>
	)
}
