import { useEffect } from 'react'
import { useLogout } from '@refinedev/core'

const LogoutPage = () => {
	const { mutate: logout } = useLogout()

	useEffect(() => {
		logout()
	})

	return <></>
}

export default LogoutPage
