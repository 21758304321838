import { Progress, Row, Tooltip, Typography } from 'antd'

import { formatCurrency } from '../utils/formatting'
import { greenBright, grey, violetBright } from '../theme'

const { Paragraph } = Typography

const MultiBarProgressWithTooltips = ({ segments }) => (
	<div style={{ position: 'relative', width: '100%', height: '100%' }}>
		<div style={{ position: 'absolute', width: '100%', height: '100%', zIndex: segments.length + 1 }}>
			{segments.map((segment, index, array) => {
				const prevPercent = (index && array[index - 1]?.percent) || 0

				return (
					<Tooltip key={index} title={segment.label} color={segment.color}>
						<div
							style={{
								height: '24px',
								cursor: 'pointer',
								position: 'absolute',
								left: `${prevPercent}%`,
								width: `${segment.percent - prevPercent}%`
							}}
						/>
					</Tooltip>
				)
			})}
		</div>
		{segments.map((segment, index) => (
			<Progress
				key={index}
				status='active'
				showInfo={false}
				percent={segment.percent}
				strokeColor={segment.color}
				trailColor={index !== 0 ? 'transparent' : undefined}
				style={{
					position: 'absolute',
					width: '100%',
					zIndex: segments.length - index
				}}
			/>
		))}
	</div>
)

const DealProgress = ({ soft_commitment = 0, hard_commitment = 0, allocation = 0, wait_list_commitment = 0 }) => {
	const total = allocation + wait_list_commitment
	const total_commitment = hard_commitment + soft_commitment
	const hard_percent = total && (hard_commitment / total) * 100
	const soft_percent = total && (soft_commitment / total) * 100

	return (
		<div style={{ display: 'flex', width: '100%', flexDirection: 'column', marginBottom: 16 }}>
			<Row justify='space-between'>
				<Paragraph style={{ margin: 0, color: grey, fontSize: 12 }} strong>
					{formatCurrency(total_commitment)}
				</Paragraph>
				<Paragraph style={{ margin: 0, color: grey, fontSize: 12 }} strong>
					{!wait_list_commitment
						? formatCurrency(allocation)
						: `${formatCurrency(allocation)} (+${formatCurrency(wait_list_commitment)} wait list)`}
				</Paragraph>
			</Row>
			<MultiBarProgressWithTooltips
				segments={[
					{
						color: violetBright,
						percent: hard_percent,
						label: `Hard: ${formatCurrency(hard_commitment)}`
					},
					{
						color: greenBright,
						percent: hard_percent + soft_percent,
						label: `Soft: ${formatCurrency(soft_commitment)}`
					},
					...[
						wait_list_commitment && {
							color: 'grey',
							percent: 100,
							label: `Wait List: ${formatCurrency(wait_list_commitment)}`
						}
					]
				].filter(Boolean)}
			/>
		</div>
	)
}

export default DealProgress
