import Icon from '@ant-design/icons'
import { Card, Space, Typography } from 'antd'

import { grey } from '../theme'

const { Title, Paragraph } = Typography

const ValueCard = ({
	title,
	value,
	subValue,
	color,
	subValueColor,
	icon = <Icon style={{ fontSize: 24 }} />,
	width
}) => {
	return (
		<Card
			style={{ width: width || 165, height: '100%', border: 'none', backgroundColor: color }}
			bodyStyle={{ padding: 16 }}
		>
			<Space direction='vertical' size='middle' style={{ width: '100%' }}>
				{icon}
				<div style={{ height: 42, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
					<Space style={{ display: 'flex', alignItems: 'center' }}>
						<Title level={2} style={{ margin: 0 }}>
							{value}
						</Title>
						<Paragraph style={{ fontSize: 12, margin: 0, color: subValueColor }}>{subValue}</Paragraph>
					</Space>
					<Paragraph style={{ fontSize: 12, margin: 0, color: grey }}>{title}</Paragraph>
				</div>
			</Space>
		</Card>
	)
}

export default ValueCard
