import dataProvider, {generateFilter, generateSort} from "@refinedev/simple-rest";
import {stringify} from "query-string";

const simpleRestProvider = (apiUrl, httpClient) => ({
  ...dataProvider(apiUrl, httpClient),

  getList: async ({resource, pagination, filters, sorters}) => {
    const url = `${apiUrl}/${resource}`;

    const {
      current = 1,
      pageSize = 10,
      mode = "server",
    } = pagination ?? {};

    const queryFilters = generateFilter(filters);

    const query = {};
    if (mode === "server") {
      query.offset = (current - 1) * pageSize;
      query.limit = pageSize;
    }

    const generatedSort = generateSort(sorters);
    if (generatedSort) {
      const {_sort, _order} = generatedSort;
      query.sort = _sort.join(",");
      query.order = _order.join(",");
    }

    const {data} = await httpClient.get(
      `${url}?${stringify(query)}&${stringify(queryFilters)}`,
    );

    return {
      data: data.rows,
      total: data.count || data.rows?.length,
    };
  },


  update: async ({resource, id, variables}) => {
    const url = `${apiUrl}/${resource}/${id}`;
    return await httpClient.put(url, variables);
  }
})

export default simpleRestProvider
