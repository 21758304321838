/** @jsxImportSource @emotion/react */
import { useTranslation } from 'react-i18next'
import { I18N_REPORT_NS } from '../../../i18n'
import { Space, Grid, Image, Typography } from 'antd'
import { css } from '@emotion/css'
import { paragraphStyle } from '../../../theme'
import InfoButton from '../../../components/buttons/infoButton'
const { useBreakpoint } = Grid

const vertSpaceProps = { direction: 'vertical', style: { width: '100%' } }
const getColorPer = per => (per < 0 ? 'var(--red-bright)' : 'var(--green-bright)')
export const renderValue = value => {
	if (value === null) return '-'
	if (value === undefined) return '-'
	const valueLength = String(value).length
	if (valueLength > 6) return String(value).slice(0, valueLength - 6) + 'M'
	if (valueLength > 3) return String(value).slice(0, valueLength - 3) + 'K'
	return value
}

const MinusInfo = ({ quarter }) => {
	const { t } = useTranslation([I18N_REPORT_NS])
	const { xs } = useBreakpoint()

	if (xs)
		return (
			<Typography.Text className={css({ ...paragraphStyle, fontSize: 10, lineHeight: 1 })}>
				{t('minus_info', { quarter })}
			</Typography.Text>
		)

	return <InfoButton info={t('minus_info', { quarter })} />
}

const MetricsCard = ({ title, value, color, percentage, quarter, prev_quarter }) => {
	const { t } = useTranslation([I18N_REPORT_NS])
	return (
		<Space direction='vertical'>
			<Typography.Text strong className={css({ whiteSpace: 'nowrap' })}>
				{title}
			</Typography.Text>
			<Space size={16} wrap>
				<Typography.Text className={css({ whiteSpace: 'nowrap' })}>{value ?? '-'}</Typography.Text>
				{percentage === 0 ? (
					<Typography.Text className={css({ color: 'var(--grey)', whiteSpace: 'nowrap' })}>
						{t('no_change_info', { quarter: prev_quarter })}
					</Typography.Text>
				) : (
					<Typography.Text className={css({ color: color ?? 'var(--grey)', whiteSpace: 'nowrap' })} strong>
						{percentage && `${percentage > 0 ? '+' : ''}${percentage}%`}
					</Typography.Text>
				)}
				{percentage < 0 ? <MinusInfo quarter={quarter} /> : null}
			</Space>
		</Space>
	)
}

export const ReportHeader = ({
	project_name,
	logo,
	valuation,
	cash_in_the_bank,
	burn_rate,
	valuation_per,
	burn_rate_per,
	cash_in_the_bank_per,
	quarter,
	prev_quarter
}) => {
	const { t } = useTranslation([I18N_REPORT_NS])
	const { xs } = useBreakpoint()

	return (
		<Space {...vertSpaceProps}>
			<Space
				className={css({
					display: 'flex',
					alignItems: 'center',
					marginBottom: 20
				})}
			>
				{logo && (
					<Image
						src={`data:image/png;base64, ${logo}`}
						alt='Logo'
						style={{ padding: 8, borderRadius: 10, height: 44, backgroundColor: 'var(--violet-bg)' }}
					/>
				)}
				<Typography.Title style={{ margin: 0 }}>{project_name}</Typography.Title>
			</Space>
			<Space size={xs ? 24 : 40} direction={xs ? 'vertical' : 'horizontal'} wrap>
				<MetricsCard
					title={t('Valuation')}
					value={renderValue(valuation)}
					percentage={valuation_per}
					color={getColorPer(valuation_per)}
					quarter={quarter}
					prev_quarter={prev_quarter}
				/>
				<MetricsCard
					title={t('Cash in the bank')}
					value={renderValue(cash_in_the_bank)}
					percentage={cash_in_the_bank_per}
					color={getColorPer(cash_in_the_bank_per)}
					quarter={quarter}
				/>
				<MetricsCard
					title={t('Burn Rate (monthly)')}
					value={renderValue(burn_rate)}
					percentage={burn_rate_per}
					color={getColorPer(burn_rate_per)}
					quarter={quarter}
					prev_quarter={prev_quarter}
				/>
			</Space>
		</Space>
	)
}
