import { TextField } from '@refinedev/antd'
import { Table } from 'antd'
import { useTranslation } from 'react-i18next'
import { I18N_FORM_NS } from '../../../i18n'
import { css } from '@emotion/css'
import dayjs from 'dayjs'
import quarterOfYear from 'dayjs/plugin/quarterOfYear'
dayjs.extend(quarterOfYear)

const textAreaFields = [
	'valuation',
	'investment_events',
	'cash_in_the_bank',
	'burn_rate',
	'run_way',
	'revenue',
	'ebitda',
	'mrr',
	'key_metrics'
]

export const ReportTable = ({ tableProps }) => {
	const { t } = useTranslation([I18N_FORM_NS])

	const columns = [
		{
			title: t('fields.quarter'),
			dataIndex: 'quarter',
			width: 140,
			fixed: true,
			render: v => <TextField value={v && `${dayjs(v).year()}-Q${dayjs(v).quarter()}`} />
		},
		...textAreaFields.map(field => ({
			title: t(`fields.${field}`),
			dataIndex: field,
			width: 120,
			render: value => (
				<TextField
					title={value}
					value={value}
					className={css({
						display: '-webkit-box',
						WebkitLineClamp: 4,
						WebkitBoxOrient: 'vertical',
						overflow: 'hidden',
						textOverflow: 'ellipsis'
					})}
				/>
			)
		}))
	]

	const reports = tableProps?.dataSource ?? []

	return <Table rowKey='id' columns={columns} {...tableProps} dataSource={reports} pagination={false} bordered />
}
