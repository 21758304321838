import { useParsed } from '@refinedev/core'
import { Breadcrumb, Typography } from 'antd'

const { Title } = Typography

// todo: to i18n
const titleKeyByPathname = {
	'': ['Showcase', '/'],
	deals: ['Showcase', '/'],
	investments: ['Portfolio & Deals', '/investments'],
	funds: ['Portfolio & Deals', '/investments']
}

function BreadcrumbItem(title, href) {
	this.href = href
	this.title = title
}

const BreadcrumbTitle = ({ current, currentTitleComponent, crumbs = [] }) => {
	const { pathname } = useParsed()

	const breadcrumbPath = pathname?.split('/').splice(1)
	const items = breadcrumbPath
		.map(b => (titleKeyByPathname[b] ? new BreadcrumbItem(...titleKeyByPathname[b]) : null))
		.filter(Boolean)

	if (current) {
		items.push({ title: current })
	}

	return (
		<div style={{ display: 'flex', flexDirection: 'column' }}>
			<Title level={1} style={{ margin: 0 }}>
				{currentTitleComponent ||
					current ||
					titleKeyByPathname[breadcrumbPath?.[breadcrumbPath.length - 1]]?.[0]}
			</Title>
			<Breadcrumb separator='>' items={[{ title: 'Homepage', href: '/' }, ...items, ...crumbs]} />
		</div>
	)
}

export default BreadcrumbTitle
