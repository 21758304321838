import { css } from '@emotion/react'

export const classes = {
    form: css`
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 0 32px;
        @media screen and (max-width: 760px) {
            grid-template-columns: 1fr;
        }
        @media screen and (min-width: 1840px) {
            grid-template-columns: 1fr;
        }
    `,
    initial: css`
        background-color: var(--violet-bg);
    `,
}
