/** @jsxImportSource @emotion/react */
import { useApiUrl, useCustom, useParsed } from '@refinedev/core'
import dayjs from 'dayjs'
import { Card, Divider, Grid, Space } from 'antd'

import BreadcrumbTitle from '../../components/breadcrumbTitle'
import { useLocation } from 'react-router-dom'
import LayoutRow from '../../components/layouts/layoutRow'
import { classes } from '../style'
import { InvestmentEvents, MetricsTable, ReportHeader, Revenue, Runway, SyndicatorSummary } from './components'

const { useBreakpoint } = Grid

const vertSpaceProps = { direction: 'vertical', style: { width: '100%' } }

export const renderQuarter = quarter => {
	if (quarter === null) return '-'
	if (quarter === undefined) return '-'
	return `Q${dayjs(quarter).quarter()}'${dayjs(quarter).format('YY')}`
}

const getPercentageIncrease = (newValue, oldValue) => {
	if (oldValue === 0 && newValue === 0) return 0
	if (oldValue === 0) return 100
	return Math.ceil(((newValue - oldValue) / oldValue) * 100)
}

const HideableDivider = () => {
	const { xs } = useBreakpoint()

	return !xs && <Divider />
}

const ReportPageBody = ({ report, reports, deal, dealId, quarter }) => {
	const { xs } = useBreakpoint()
	const { logo, project_name } = deal

	const prevReportIndex = reports?.findIndex(r => r.quarter === report.quarter) - 1
	const prevReport = reports?.[prevReportIndex]

	const valuation_per =
		prevReport?.valuation || prevReport?.valuation === 0
			? getPercentageIncrease(report.valuation, prevReport.valuation)
			: undefined
	const burn_rate_per =
		prevReport?.burn_rate || prevReport?.burn_rate === 0
			? getPercentageIncrease(report.burn_rate, prevReport.burn_rate)
			: undefined
	const cash_in_the_bank_per =
		prevReport?.cash_in_the_bank || prevReport?.cash_in_the_bank === 0
			? getPercentageIncrease(report.cash_in_the_bank, prevReport.cash_in_the_bank)
			: undefined
	const revenue_per =
		prevReport?.revenue || prevReport?.revenue === 0
			? getPercentageIncrease(report.revenue, prevReport.revenue)
			: undefined

	const prev_quarter = renderQuarter(prevReport?.quarter)

	const investmenEvents = reports
		?.map(({ investment_events }) => investment_events)
		?.filter(investment_event => investment_event)

	const runwayList = reports?.map(({ run_way }) => run_way)?.filter(run_way => run_way)

	return (
		<Space {...vertSpaceProps} size={!xs ? 'small' : 'large'}>
			{xs ? (
				<Card>
					<ReportHeader
						{...{
							...report,
							valuation_per,
							burn_rate_per,
							cash_in_the_bank_per,
							project_name,
							logo,
							quarter,
							prev_quarter
						}}
					/>
				</Card>
			) : (
				<ReportHeader
					{...{
						...report,
						valuation_per,
						burn_rate_per,
						cash_in_the_bank_per,
						project_name,
						logo,
						quarter,
						prev_quarter
					}}
				/>
			)}
			<HideableDivider />
			<SyndicatorSummary reports={reports} />

			{investmenEvents.length && runwayList.length ? (
				<>
					<HideableDivider />
					<LayoutRow
						firstColumn={<InvestmentEvents reports={reports} />}
						secondColumn={<Runway reports={reports} />}
					/>
				</>
			) : null}
			{!investmenEvents.length || !runwayList.length ? (
				<>
					{!investmenEvents.length && !runwayList.length ? null : <HideableDivider />}
					<LayoutRow
						firstColumn={
							runwayList.length ? (
								<Runway reports={reports} />
							) : investmenEvents.length ? (
								<InvestmentEvents reports={reports} />
							) : null
						}
						secondColumn={null}
					/>
				</>
			) : null}
			<HideableDivider />
			{xs ? (
				<Card>
					<Space css={classes.row} size={60}>
						<Revenue
							report={report}
							reports={reports}
							revenue_per={revenue_per}
							prev_quarter={prev_quarter}
						/>
						<MetricsTable reports={reports} />
					</Space>
				</Card>
			) : (
				<Space css={classes.row} size={60}>
					<Revenue report={report} reports={reports} revenue_per={revenue_per} prev_quarter={prev_quarter} />
					<MetricsTable reports={reports} />
				</Space>
			)}
		</Space>
	)
}

const ReportPage = () => {
	const { id } = useParsed()
	const { xs } = useBreakpoint()
	const { pathname, search } = useLocation()
	const apiUrl = useApiUrl()

	const dealId = pathname.split('/')?.[2]
	const quarterSearch = search.split('=')?.[1]

	const { data: { data: { rows } = {} } = {} } = useCustom({
		url: `${apiUrl}/project_reports`,
		config: {
			query: {
				deal_id: dealId,
				quarter_to: quarterSearch
			}
		}
	})

	const { data: { data: deal = {} } = {} } = useCustom({
		url: `${apiUrl}/deals/${dealId}`
	})

	const reports = rows?.sort((a, b) => a.quarter && b.quarter && dayjs(a.quarter).diff(dayjs(b.quarter)))
	const report = reports?.find(report => report.id === id)
	const quarter = report?.quarter && `Q${dayjs(report.quarter).quarter()}'${dayjs(report.quarter).format('YY')}`
	const title = report?.quarter && `Report Q${dayjs(report.quarter).quarter()}'${dayjs(report.quarter).format('YY')}`
	const crumbs = [
		{ title: deal?.project_name, href: `/deals/${dealId}` },
		{ title: quarter, href: `/report` }
	]
	return (
		report &&
		(!xs ? (
			<Space {...vertSpaceProps} size='large'>
				<BreadcrumbTitle currentTitleComponent={title} crumbs={crumbs} />
				<Card>
					<ReportPageBody {...{ report, reports, deal, quarter }} dealId={dealId} />
				</Card>
			</Space>
		) : (
			<ReportPageBody {...{ report, reports, deal }} dealId={dealId} style={{ padding: '10px' }} />
		))
	)
}

export default ReportPage
