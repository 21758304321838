export const primaryColor = '#282071'
export const secondaryColor = '#5850EC'

export const greySearchBorder = '#D6D6D6'
export const greyBackground = '#F1F5F9'
export const greyBorder = '#E6E7E9'
export const grey = '#82868F'

export const yellowBright = '#FFC01F'
export const yellowLight = '#FFF2D0'
export const violetBright = '#5850EC'
export const violetLight = '#CDCBF9'
export const violetDark = '#282071'
export const violetBg = '#F1F5F9'
export const greenBright = '#48BB78'
export const greenLight = '#DFFCEB'
export const redBright = '#E21E1E'
export const redLight = '#FFC9C9'

export const defaultShadow = '0px 4px 3px -2px rgba(0, 0, 0, 0.06)'
export const paragraphColor = '#2C4351'

export const tagsTextColor = 'black'

export const paragraphStyle = { whiteSpace: 'pre-line', fontSize: 12, color: paragraphColor }

export const layoutBackgroundStyle = {
	background:
		'linear-gradient(0deg, rgba(26, 32, 46, 0.60) 0%, rgba(26, 32, 46, 0.60) 100%), url(/adgvBackground.png), lightgray 50% / cover no-repeat',
	backgroundSize: 'cover',
	backgroundPosition: '47.5%',
	backgroundColor: '#3f2571'
}

const antdTheme = {
	components: {
		Button: {
			controlOutline: null,
			colorLinkHover: secondaryColor,
			colorPrimaryHover: secondaryColor
		},
		Progress: {
			marginXS: 0
		},
		Input: {
			colorBorder: greyBorder
		},
		Menu: {
			darkItemBg: null,
			darkSubMenuItemBg: null
		},
		Pagination: {
			colorBgContainer: violetLight,
			colorPrimary: 'white'
		},
		Typography: {
			fontSizeHeading1: 24,
			fontSizeHeading2: 20,
			fontSizeHeading3: 16,
			fontSizeHeading4: 12,
			fontSizeHeading5: 8
		}
		// Tag: {
		// 	fontSize: 10,
		// 	fontSizeSM: 10,
		// 	borderRadiusSM: 5
		// }
	},
	token: {
		borderRadius: 5,
		colorInfo: primaryColor,
		boxShadow: defaultShadow,
		colorPrimary: primaryColor,
		colorBgLayout: greyBackground,
		boxShadowSecondary: defaultShadow,
		fontFamily: "Manrope, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial"
	}
}

export default antdTheme
