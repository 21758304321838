import debounce from 'lodash.debounce'
import { useState } from 'react'
import { Select } from 'antd'

import { useQuerySelectorProps } from './querySelect'

const CITY_SEARCH_DEBOUNCE = 500

const CityInput = props => {
	const [city, setCity] = useState('')

	const { options, selectorProps } = useQuerySelectorProps({
		path: `/cities/${city}`,
		optionsGetter: data => data?.data.map(({ id, name }) => ({ value: id, label: name })),
		enabled: !!city
	})

	return (
		<Select
			onSearch={debounce(v => setCity(v), CITY_SEARCH_DEBOUNCE)}
			defaultActiveFirstOption={false}
			notFoundContent={null}
			filterOption={false}
			options={options}
			{...selectorProps}
			{...props}
			loading={false}
		/>
	)
}

export default CityInput
