import { useTranslation } from 'react-i18next'
import { Edit, useForm } from '@refinedev/antd'
import { Form, Col, Row, Select, InputNumber, Switch } from 'antd'

import { I18N_SETTINGS_NS } from '../../i18n'
import { RemoteSelector } from '../../components/selects/querySelect'

const { Item } = Form

const commitTypeOptions = [
	{ value: 'soft', label: 'Soft' },
	{ value: 'hard', label: 'Hard' }
]

const SettingsDefaultsPage = () => {
	const { t } = useTranslation([I18N_SETTINGS_NS])

	const { formProps, saveButtonProps } = useForm({
		resource: 'investors',
		action: 'edit',
		id: 'me'
	})

	return (
		<Edit title='Investment Defaults' saveButtonProps={saveButtonProps} headerButtons={[]} breadcrumb={false}>
			<Form {...formProps} labelCol={{ span: 4 }} labelAlign='left'>
				<Item name='default_commit_vehicle_id' label={t('default.commit_vehicle_id')}>
					<RemoteSelector path={'/legal_entities'} />
				</Item>
				<Item name='default_commit_amount' label={t('default.commit_amount')}>
					<InputNumber addonBefore='$' min={0} style={{ width: '100%' }} />
				</Item>
				<Row gutter={[16, 0]} justify='space-between'>
					<Col span={16}>
						<Item name='default_commit_type' label={t('default.commit_type')} labelCol={{ span: 6 }}>
							<Select options={commitTypeOptions} />
						</Item>
					</Col>
					<Col>
						<Item
							name='default_commit_anonymous'
							label={t('default.commit_anonymous')}
							valuePropName='checked'
							labelCol={{ span: 0 }}
						>
							<Switch />
						</Item>
					</Col>
				</Row>
			</Form>
		</Edit>
	)
}

export default SettingsDefaultsPage
