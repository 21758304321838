import dayjs from 'dayjs'
import { useState } from 'react'

import { useTranslation } from 'react-i18next'
import { useApiUrl, useCustom, useNavigation } from '@refinedev/core'
import { Card, Space, Typography, Pagination } from 'antd'

import { InfoCard } from '../pages/deal'

import { I18N_DEAL_NS } from '../i18n'
import { TextField } from '@refinedev/antd'
import { css } from '@emotion/css'

import { violetDark, paragraphStyle } from '../theme'

const { Title } = Typography

const REPORTS_PER_PAGE = 4

const ReportsCard = ({ id: dealId, state }) => {
	const [currentPage, setCurrentPage] = useState(1)
	const { push } = useNavigation()

	const { t } = useTranslation([I18N_DEAL_NS])

	const apiUrl = useApiUrl()

	const { data: { data: { rows: reports, count: total } = {} } = {} } = useCustom({
		url: `${apiUrl}/project_reports`,
		config: {
			query: {
				deal_id: dealId,
				limit: REPORTS_PER_PAGE,
				offset: (currentPage - 1) * REPORTS_PER_PAGE
			}
		}
	})

	const moveToQuarter = (reportId, quarter) => {
		push(`report/${reportId}?quarter=${quarter}`)
	}

	// if (state !== 'completed') return null

	return (
		<InfoCard>
			<Space direction='vertical' size='middle' style={{ width: '100%' }}>
				<Title level={3}>{t('reports')}</Title>
				<Space wrap size='small'>
					{reports?.length ? (
						reports
							.sort((a, b) => a.quarter && b.quarter && dayjs(a.quarter).diff(dayjs(b.quarter)))
							.map(report => (
								<Card
									size='small'
									key={report.id}
									hoverable
									onClick={() => moveToQuarter(report.id, report.quarter)}
								>
									<TextField
										value={
											report.quarter &&
											`Q${dayjs(report.quarter).quarter()}'${dayjs(report.quarter).format('YY')}`
										}
										className={css({
											display: '-webkit-box',
											WebkitLineClamp: 2,
											WebkitBoxOrient: 'vertical',
											overflow: 'hidden',
											textOverflow: 'ellipsis',
											color: violetDark,
											fontWeight: 600,
											fontSize: 12
										})}
									/>
								</Card>
							))
							.filter(Boolean)
					) : (
						<Typography.Text className={css({ ...paragraphStyle })}>{t('noReports')}</Typography.Text>
					)}
				</Space>

				{total ? (
					<Pagination
						total={total}
						onChange={setCurrentPage}
						pageSize={REPORTS_PER_PAGE}
						defaultCurrent={1 || currentPage}
					/>
				) : null}
			</Space>
		</InfoCard>
	)
}

export default ReportsCard
