import { css } from '@emotion/react'

export const classes = {
    tooltip: css`
         &[data-title]:hover:after {
            opacity: 1;
            transition: all 0.1s ease 0.5s;
            visibility: visible;
        }
        &[data-title]:after {
            content: attr(data-title);
            background-color: var(--info-bg);
            position: absolute;
            font-size: 10px;
            padding: 4px 8px;
            width: 210px;
            bottom: -64px;
            right: 18px;
            border-radius: 4px;
            opacity: 0;
            border: none;
            z-index: 99999;
            visibility: hidden;
        }
        &[data-title] {
            position: relative;
        }
    `,
    btn: css({
        backgroundColor: 'var(--info-bg)',
        borderRadius: 2,
        width: 16,
        height: 16,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    }),
    icon: css({
        color: 'var(--info)',
        fontSize: 8,
        borderRadius: 10,
        border: '1px solid var(--info)',
        width: 12,
        height: 12,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer'
    })
}
