import { Col, Row, Space, Grid } from 'antd'
const { useBreakpoint } = Grid

const LayoutRow = ({ firstColumn, secondColumn, ...props }) => {
	const { xs } = useBreakpoint()

	return !xs ? (
		<Row gutter={[72, 72]} {...props}>
			<Col span={14}>{firstColumn}</Col>
			<Col span={10}>{secondColumn}</Col>
		</Row>
	) : (
		<Space direction='vertical' style={{ width: '100%' }} size='large' {...props}>
			{firstColumn}
			{secondColumn}
		</Space>
	)
}

export default LayoutRow
