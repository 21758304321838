import { css } from '@emotion/react'

export const classes = {
    columns: css`
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        align-items: flex-start;
        @media screen and (max-width: 1670px) {
            grid-template-columns: repeat(3, 1fr);
        }
        @media screen and (max-width: 1320px) {
            grid-template-columns: repeat(2, 1fr);
        }
        @media screen and (max-width: 960px) {
            grid-template-columns: repeat(1, 1fr);
        }
    `,
    row: css`
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        align-items: flex-start;
        &:not(:last-child) {
            width: 60%;
        }
        @media screen and (max-width: 1320px) {
         display: flex;
         flex-wrap: wrap;
         max-width: 900px;
         margin: 0 auto;
        }
        .ant-space-item {
                width: 100%;
                overflow-x: auto;
            }
    `,
}
