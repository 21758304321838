import { Input } from 'antd'

// import { greySearchBorder } from '../../theme'

const SearchInput = ({ loading, onSearch }) => {
	return (
		<Input.Search
			placeholder='Search'
			loading={loading}
			onSearch={onSearch}
			size='middle'
			// style={{ border: `1px solid ${greySearchBorder}`, borderRadius: 5 }}
		/>
	)
}

export default SearchInput
