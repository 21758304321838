import { useLogin } from '@refinedev/core'
import { Layout, Space, Button } from 'antd'
import { useTranslation } from 'react-i18next'
import TelegramLoginButton from 'react-telegram-login'

import { I18N_GENERIC_NS, manualHref } from '../i18n'

import { ReactComponent as ADGVLogo } from '../icons/adgvLogo.svg'

import { layoutBackgroundStyle } from '../theme'

const ManualButton = () => {
	const { t } = useTranslation([I18N_GENERIC_NS])

	return (
		<Button
			style={{
				border: 0,
				width: 220,
				height: 40,
				fontSize: 16,
				fontWeight: 500,
				borderRadius: 20,
				padding: '6px 15px'
			}}
			href={manualHref}
			target='_blank'
		>
			{t('manual')}
		</Button>
	)
}

const LoginPage = () => {
	const { mutate: login } = useLogin()

	const handleTelegramResponse = response => login(response)

	return (
		<Layout
			style={{
				height: '100vh',
				justifyContent: 'center',
				alignItems: 'center',
				...layoutBackgroundStyle
			}}
		>
			<Space direction='vertical' align='center' size='large'>
				<ADGVLogo />
				<Space direction='vertical' align='center' size='small'>
					<TelegramLoginButton dataOnauth={handleTelegramResponse} botName={process.env.REACT_APP_BOT_NAME} />
					<ManualButton />
				</Space>
			</Space>
		</Layout>
	)
}

export default LoginPage
