import { Route, Outlet } from 'react-router-dom'

import { ReactComponent as ShowcaseIcon } from '../icons/siderShowcase.svg'
import { ReactComponent as SettingsIcon } from '../icons/siderSettings.svg'
import { ReactComponent as PortfolioIcon } from '../icons/siderPortfolio.svg'

import DealPage from './deal'
import FundPage from './fund'
import LoginPage from './login'
import LogoutPage from './logout'
import ShowcasePage from './showcase'
import PortfolioPage from './portfolio'
import { SettingsProfilePage } from './settings'
import ReportPage from './report'

// Every page here is a route, not hidden first-level pages are also sider menu items
// Page without element and with nested pages is a sub-menu for nested pages
// By default path of route is a key
const pages = {
	showcase: {
		path: '',
		icon: <ShowcaseIcon />,
		element: <ShowcasePage />,
		nested: {
			'deals/:id': {
				element: <DealPage />,
				nested: {
					'report/:id': {
						element: <ReportPage />
					}
				}
			}
		}
	},
	investments: {
		icon: <PortfolioIcon />,
		element: <PortfolioPage />
	},
	fund: {
		path: 'funds/:id',
		element: <FundPage />,
		notIncludeInMenu: true
	},
	// achievements: {
	// 	icon: <CheckOutlined />
	// },
	// wallet: {
	// 	icon: <WalletOutlined />,
	// 	element: <WalletPage />
	// },
	// bot: {
	// 	icon: <RobotOutlined />,
	// 	withDivider: true
	// },
	settings: {
		icon: <SettingsIcon />,
		nested: {
			profile: {
				element: <SettingsProfilePage />
			},
			// defaults: {
			// 	element: <SettingsDefaultsPage />
			// },
			logout: {
				element: <LogoutPage />
			}
		}
	}
}

const getPagesRoutes = (pgs = pages) =>
	Object.entries(pgs).map(([key, { path, element, nested }]) => (
		<Route key={key} path={path === undefined ? key : path} element={nested ? <Outlet /> : element || <>{key}</>}>
			{nested && <Route index element={element} />}
			{nested && getPagesRoutes(nested)}
		</Route>
	))

export { pages, getPagesRoutes, LoginPage, LogoutPage }
export default pages
