/** @jsxImportSource @emotion/react */
import { css } from '@emotion/css'
import { Button, Form, InputNumber, Select, Space } from 'antd'
import TextArea from 'antd/es/input/TextArea'
import { useTranslation } from 'react-i18next'
import { I18N_FORM_NS } from '../../../../i18n'
import { UrlInput } from './UrlInput'
import { useCallback, useEffect } from 'react'
import { useApiUrl, useCustomMutation } from '@refinedev/core'
import { classes } from './style'

const textAreaFields = ['run_way', 'ebitda', 'key_metrics', 'syndicator_summary']

const numberFields = ['valuation', 'cash_in_the_bank', 'burn_rate', 'revenue', 'mrr']

export const ReportForm = ({ public_id, refetch }) => {
	const [form] = Form.useForm()
	const { t } = useTranslation([I18N_FORM_NS])
	const { mutate } = useCustomMutation()
	const apiUrl = useApiUrl()

	useEffect(() => {
		form.resetFields()
	}, [form])

	const handleFormSubmit = useCallback(
		values => {
			mutate(
				{
					url: `${apiUrl}/report_form/version?public_id=${public_id}`,
					method: 'post',
					values,
					successNotification: {
						message: t('notifications.success'),
						type: 'success'
					},
					errorNotification: {
						message: t('notifications.error'),
						type: 'error'
					}
				},
				{
					onSuccess: async () => {
						refetch()
					}
				}
			)
		},
		[mutate, apiUrl, public_id, t, refetch]
	)

	return (
		<Form layout={'vertical'} form={form} onFinish={handleFormSubmit} css={classes.form}>
			<Form.Item
				label={t('fields.material_url')}
				name='material_url'
				className={css({
					marginBottom: 8
				})}
			>
				<UrlInput />
			</Form.Item>
			{numberFields.map(field => (
				<Form.Item
					key={field}
					label={t(`fields.${field}`)}
					name={field}
					rules={[{ required: true }]}
					className={css({ marginBottom: 8 })}
				>
					<InputNumber
						allowClear
						addonAfter='$'
						className={css({
							width: '100%'
						})}
					/>
				</Form.Item>
			))}
			{textAreaFields.map(field => (
				<Form.Item
					key={field}
					label={t(`fields.${field}`)}
					name={field}
					rules={[{ required: true }]}
					className={css({ marginBottom: 8 })}
				>
					<TextArea rows={1} allowClear />
				</Form.Item>
			))}
			<Form.Item
				label={t(`fields.author`)}
				name='author'
				className={css({
					marginBottom: 8
				})}
				rules={[{ required: true }]}
			>
				<Select
					options={[
						{ label: 'Startup', value: 'startup' },
						{ label: 'Syndicator', value: 'syndicator' }
					]}
				/>
			</Form.Item>
			<Space direction='horizontal' size={24} className={css({ width: '100%', marginTop: 32 })}>
				<Form.Item>
					<Button type='primary' htmlType='submit' style={{ width: 140 }}>
						{t('btn.submit')}
					</Button>
				</Form.Item>
			</Space>
		</Form>
	)
}
