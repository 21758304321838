import { Select } from 'antd'
import { useSelect } from '@refinedev/antd'

const defaultResourcesFactory = data => {
	const d = data.data || data
	return d?.rows || d
}

export const useEntitySelectorProps = ({
	value,
	resource,
	defaultValue,
	resourcesFactory = defaultResourcesFactory,
	nameFactory
}) => {
	const { selectProps, queryResult } = useSelect({
		resource,
		debounce: 500,
		optionLabel: 'name',
		defaultValue: defaultValue || value,
		onSearch: value => [
			{
				field: 'search',
				value
			}
		],
		queryOptions: {
			select: data => ({
				...data,
				data: resourcesFactory(data).map(o => ({ ...o, name: nameFactory ? nameFactory(o) : o.name }))
			})
		}
	})

	return { ...selectProps, loading: queryResult?.isFetching }
}

export const EntitySelector = ({
	resourcesFactory = defaultResourcesFactory,
	value,
	label,
	onChange,
	resource,
	nameFactory,
	placeholder,
	defaultValue,
	disableWhenFilled,
	...props
}) => {
	const selectProps = useEntitySelectorProps({
		value,
		resource,
		defaultValue,
		resourcesFactory,
		nameFactory
	})

	return (
		<Select
			label={label}
			onChange={onChange}
			style={{ width: '100%' }}
			placeholder={placeholder}
			defaultValue={defaultValue || props?.value}
			disabled={value && disableWhenFilled}
			value={selectProps.loading ? null : value}
			{...selectProps}
			{...props}
		/>
	)
}

export const LegalEntitySelector = ({ defaultValue, onChange, ...props }) => (
	<EntitySelector
		label='Legal entity'
		defaultValue={defaultValue}
		onChange={onChange}
		resource='legal_entities'
		placeholder='Select legal entity'
		nameFactory={d => d.name}
		{...props}
	/>
)
