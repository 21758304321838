/** @jsxImportSource @emotion/react */
import { useApiUrl, useCustom, useLogout, useParsed } from '@refinedev/core'
import { Card, Layout, Space, Typography } from 'antd'
import { ReactComponent as ADGVLogo } from '../../icons/adgvLogo.svg'
import { useTranslation } from 'react-i18next'
import { I18N_FORM_NS } from '../../i18n'
import { css } from '@emotion/css'
import { ReportForm, ReportTable } from './components'
import { useEffect, useState } from 'react'
import LangSwitcher from '../../components/langSwitcher'
import { classes } from './style'
import dayjs from 'dayjs'
import { useTable } from '@refinedev/antd'

const FormPage = () => {
	const { mutate: logout } = useLogout()

	const { t } = useTranslation([I18N_FORM_NS])
	const { params } = useParsed()
	const apiUrl = useApiUrl()

	const { public_id } = params
	const [publicIdData, setPublicIdData] = useState(undefined)

	// eslint-disable-next-line no-unused-vars
	const { data: publicId } = useCustom({
		url: `${apiUrl}/report_form/me`,
		method: 'get',
		queryOptions: {
			enabled: Boolean(public_id),
			onSuccess: data => {
				setPublicIdData(data?.data)
			}
		},
		config: {
			query: {
				public_id
			}
		}
	})

	const { tableProps, tableQueryResult } = useTable({
		resource: `report_form`,
		queryOptions: {
			enabled: Boolean(publicIdData?.project_id)
		},
		pagination: {
			mode: 'off'
		},
		filters: {
			permanent: [
				{
					field: 'public_id',
					operator: 'eq',
					value: public_id
				}
			].filter(Boolean)
		}
	})

	useEffect(() => {
		if (!public_id) {
			logout()
		}
	}, [logout, public_id])

	return (
		<Layout className={css({ height: '100vh' })}>
			<Layout.Header css={classes.header}>
				<ADGVLogo />
				<LangSwitcher />
			</Layout.Header>

			<Space css={classes.content} size={36} wrap>
				<Card
					title={
						<Space css={classes.cardTitle} align='end' wrap>
							<Typography.Title level={1} style={{ margin: 0 }}>
								{t('title')}
							</Typography.Title>
							<Space size={32}>
								<Space>
									<Typography.Text style={{ margin: 0, fontWeight: 300 }}>
										{t('project')}:
									</Typography.Text>
									<Typography.Text style={{ margin: 0 }}>
										{publicIdData?.project_name}
									</Typography.Text>
								</Space>
								<Space>
									<Typography.Text style={{ margin: 0, fontWeight: 300 }}>
										{t('quarter')}:
									</Typography.Text>
									<Typography.Text style={{ margin: 0 }}>
										{publicIdData?.quarter &&
											`${dayjs(publicIdData.quarter).year()}-Q${dayjs(
												publicIdData.quarter
											).quarter()}`}
									</Typography.Text>
								</Space>
							</Space>
						</Space>
					}
					css={classes.card}
				>
					<ReportForm {...{ public_id, refetch: tableQueryResult.refetch }} />
				</Card>
				<Card
					title={
						<Typography.Title level={1} style={{ margin: 0 }}>
							{t('reports')}
						</Typography.Title>
					}
					css={classes.card}
				>
					<ReportTable {...{ tableProps }} />
				</Card>
			</Space>
		</Layout>
	)
}

export default FormPage
