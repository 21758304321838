import { css } from '@emotion/react'
import {layoutBackgroundStyle, defaultShadow} from '../../theme'

export const classes = {
    header: css({
        height: '64px',
        display: 'flex',
        padding: '0px 24px',
        alignItems: 'center',
        boxShadow: defaultShadow,
        justifyContent: 'space-between',
        ...layoutBackgroundStyle
    }),
    content: css`
        padding: 36px;
        @media screen and (max-width: 760px) {
            padding: 12px;
        }
        width: 100%;
        height: 100%;
        overflow: auto;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        &>.ant-space-item {
            min-width: 300px;
            flex-grow: 1;
            flex-shrink: 1;
            max-width: 1200px;
        };
    `,
    cardTitle: css({
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between'
    }),
    card: css({ height: '100%', overflow: 'auto' }),
}
